import React, { SVGProps } from 'react';

export const Trophy = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M1.99999 15C1.99999 14.4477 2.4477 14 2.99999 14H13C13.5523 14 14 14.4477 14 15C14 15.5523 13.5523 16 13 16H2.99999C2.4477 16 1.99999 15.5523 1.99999 15Z"
        fill="currentcolor"
      />
      <path
        d="M6.99999 0H12.4031C13.3972 0 14.2031 0.805887 14.2031 1.8V4H15.1523C15.6758 4 16.074 4.47004 15.9879 4.98639L15.8806 5.63034C15.4291 8.33921 13.5827 10.6093 11.0226 11.603L10.9164 11.6443C10.364 11.8587 9.99999 12.3905 9.99999 12.9831V14H6.99999V0Z"
        fill="currentcolor"
      />
      <path
        d="M9.00389 0H3.60077C2.60665 0 1.80077 0.805887 1.80077 1.8V4H0.851548C0.328075 4 -0.0701113 4.47004 0.0159471 4.98639L0.123272 5.63034C0.574749 8.33921 2.42115 10.6093 4.98126 11.603L5.08743 11.6443C5.63989 11.8587 6.00389 12.3905 6.00389 12.9831V14H9.00389V0Z"
        fill="currentcolor"
      />
    </svg>
  );
};
