import React, { SVGProps } from 'react';

export const Upcoming = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM8.90004 2.69999L8.90004 7.62694L11.0901 9.81701C11.4416 10.1685 11.4416 10.7383 11.0901 11.0898C10.7386 11.4413 10.1688 11.4413 9.81732 11.0898L7.36364 8.63613C7.18774 8.46023 7.09987 8.22963 7.10004 7.99909V7.99844L7.10004 2.69999C7.10004 2.20293 7.50298 1.79999 8.00004 1.79999C8.4971 1.79999 8.90004 2.20293 8.90004 2.69999Z"
        fill="currentcolor"
      />
    </svg>
  );
};
