import { SVGProps } from 'react';

export const Share = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 14 14" {...props}>
      <path
        d="M2.9 6.19994C1.29837 6.19994 0 7.49832 0 9.09994V11.0979C0 12.6995 1.29838 13.9979 2.9 13.9979H11.1C12.7016 13.9979 14 12.6995 14 11.0979V9.09994C14 7.49831 12.7016 6.19994 11.1 6.19994H10.6015C10.1045 6.19994 9.70154 6.60289 9.70154 7.09994C9.70154 7.597 10.1045 7.99994 10.6015 7.99994H11.1C11.7075 7.99994 12.2 8.49243 12.2 9.09994V11.0979C12.2 11.7054 11.7075 12.1979 11.1 12.1979L2.9 12.1979C2.29249 12.1979 1.8 11.7054 1.8 11.0979V9.09994C1.8 8.49243 2.29249 7.99994 2.9 7.99994H3.39841C3.89547 7.99994 4.29841 7.597 4.29841 7.09994C4.29841 6.60289 3.89547 6.19994 3.39841 6.19994H2.9Z"
        fill="currentColor"
      />
      <path
        d="M10.7343 3.52946C11.0858 3.88092 11.0858 4.45077 10.7344 4.80225C10.3829 5.15373 9.81305 5.15373 9.46157 4.80227L7.90019 3.24095V9.49919C7.90019 9.99624 7.49725 10.3992 7.00019 10.3992C6.50313 10.3992 6.10019 9.99624 6.10019 9.49919V3.24099L4.53703 4.80416C4.18556 5.15563 3.61571 5.15563 3.26424 4.80416C2.91276 4.45269 2.91276 3.88284 3.26423 3.53137L6.29308 0.502505C6.6836 0.111983 7.31676 0.111979 7.70729 0.502493L10.7343 3.52946Z"
        fill="currentColor"
      />
    </svg>
  );
};
