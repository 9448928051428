import { SVGProps } from 'react';

export const SocialLinkedin = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        d="M18.8189 4H5.18111C4.52889 4 4 4.52889 4 5.18111V18.8189C4 19.4711 4.52889 20 5.18111 20H18.8189C19.4711 20 20 19.4711 20 18.8189V5.18111C20 4.52889 19.4711 4 18.8189 4ZM8.76889 17.6311H6.36333V9.98889H8.76889V17.63V17.6311ZM7.56667 8.93111C6.80111 8.93111 6.18 8.34778 6.18 7.55C6.18 6.75222 6.80111 6.16889 7.56667 6.16889C8.33222 6.16889 8.95333 6.75222 8.95333 7.55C8.95333 8.34778 8.33222 8.93111 7.56667 8.93111ZM17.6378 17.6378H15.2322V13.4644C15.2322 12.2333 14.7089 11.8533 14.0333 11.8533C13.32 11.8533 12.62 12.39 12.62 13.4956V17.6389H10.2144V9.99444H12.5278V11.0533H12.5589C12.7911 10.5833 13.6044 9.78 14.8456 9.78C16.1878 9.78 17.6378 10.5767 17.6378 12.91V17.6367V17.6378Z"
        fill="currentColor"
      />
    </svg>
  );
};
