import React, { SVGProps } from 'react';

export const Star = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M7.1045 0.957108C7.47224 0.217178 8.52778 0.217178 8.89551 0.957109L10.8291 4.84766L15.1382 5.46946C15.9606 5.58813 16.2879 6.59983 15.6909 7.17773L12.5775 10.1914L13.314 14.4556C13.4549 15.2716 12.5998 15.8955 11.8658 15.5123L8.00001 13.4941L4.13423 15.5123C3.4002 15.8955 2.54509 15.2716 2.68602 14.4556L3.42251 10.1914L0.309125 7.17773C-0.287893 6.59983 0.0394307 5.58813 0.861817 5.46946L5.17096 4.84766L7.1045 0.957108Z"
        fill="currentcolor"
      />
    </svg>
  );
};
