import { SVGProps } from 'react';

export const SocialX = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        d="M16.3134 5.2207H18.6135L13.5884 10.964L19.5 18.7793H14.8713L11.2459 14.0394L7.09769 18.7793H4.7962L10.171 12.6362L4.5 5.2207H9.24621L12.5232 9.5532L16.3134 5.2207ZM15.5061 17.4026H16.7806L8.55368 6.52511H7.186L15.5061 17.4026Z"
        fill="currentColor"
      />
    </svg>
  );
};
