import { SVGProps } from 'react';

export const SocialTelegram = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        d="M4.48092 11.5316C4.48092 11.5316 11.5573 8.62739 14.0116 7.60479C14.9524 7.19575 18.1429 5.88682 18.1429 5.88682C18.1429 5.88682 19.6154 5.31416 19.4927 6.7049C19.4518 7.27756 19.1246 9.28186 18.7974 11.4498C18.3065 14.5176 17.7748 17.8717 17.7748 17.8717C17.7748 17.8717 17.693 18.8125 16.9976 18.9761C16.3022 19.1398 15.1569 18.4035 14.9524 18.2399C14.7888 18.1172 11.8846 16.2765 10.8211 15.3766C10.5347 15.1312 10.2075 14.6403 10.862 14.0676C12.3345 12.7178 14.0934 11.0407 15.1569 9.97723C15.6477 9.48638 16.1386 8.34106 14.0934 9.7318C11.1892 11.7361 8.32591 13.6177 8.32591 13.6177C8.32591 13.6177 7.67144 14.0267 6.44432 13.6586C5.21719 13.2905 3.78555 12.7996 3.78555 12.7996C3.78555 12.7996 2.80385 12.1861 4.48092 11.5316Z"
        fill="currentColor"
      />
    </svg>
  );
};
