import { SVGProps } from 'react';

export const SocialDiscord = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        d="M17.9006 6.63684C16.7837 6.12549 15.6046 5.76272 14.3934 5.5578C14.2277 5.85409 14.0777 6.15893 13.9441 6.47104C12.654 6.27663 11.342 6.27663 10.0518 6.47104C9.91817 6.15896 9.76821 5.85412 9.60254 5.5578C8.39057 5.76445 7.21068 6.12808 6.09264 6.63952C3.87304 9.92345 3.27134 13.1258 3.57219 16.2827C4.87204 17.2431 6.32695 17.9735 7.87366 18.4422C8.22194 17.9737 8.53011 17.4768 8.79493 16.9566C8.29195 16.7688 7.80649 16.537 7.34417 16.264C7.46584 16.1758 7.58485 16.0848 7.69984 15.9966C9.0451 16.6292 10.5134 16.9572 12 16.9572C13.4866 16.9572 14.9548 16.6292 16.3001 15.9966C16.4164 16.0915 16.5354 16.1824 16.6558 16.264C16.1926 16.5374 15.7062 16.7697 15.2023 16.958C15.4668 17.4779 15.775 17.9744 16.1236 18.4422C17.6716 17.9754 19.1277 17.2453 20.4277 16.2841C20.7807 12.6231 19.8247 9.45012 17.9006 6.63684ZM9.176 14.3412C8.33764 14.3412 7.64502 13.5804 7.64502 12.6445C7.64502 11.7085 8.31357 10.941 9.17333 10.941C10.0331 10.941 10.7204 11.7085 10.7057 12.6445C10.6909 13.5804 10.0304 14.3412 9.176 14.3412ZM14.8239 14.3412C13.9842 14.3412 13.2943 13.5804 13.2943 12.6445C13.2943 11.7085 13.9628 10.941 14.8239 10.941C15.685 10.941 16.367 11.7085 16.3523 12.6445C16.3375 13.5804 15.6784 14.3412 14.8239 14.3412Z"
        fill="currentColor"
      />
    </svg>
  );
};
