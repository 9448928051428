import React, { SVGProps } from 'react';

export const Sun = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M6.79999 1.2C6.79999 0.537258 7.33725 0 7.99999 0C8.66273 0 9.19999 0.537258 9.19999 1.2C9.19999 1.86274 8.66273 2.4 7.99999 2.4C7.33725 2.4 6.79999 1.86274 6.79999 1.2ZM3.79999 8C3.79999 5.6804 5.68039 3.8 7.99999 3.8C10.3196 3.8 12.2 5.6804 12.2 8C12.2 10.3196 10.3196 12.2 7.99999 12.2C5.68039 12.2 3.79999 10.3196 3.79999 8ZM7.99999 13.6C7.33725 13.6 6.79999 14.1373 6.79999 14.8C6.79999 15.4627 7.33725 16 7.99999 16C8.66273 16 9.19999 15.4627 9.19999 14.8C9.19999 14.1373 8.66273 13.6 7.99999 13.6ZM0 8C0 7.33726 0.537258 6.8 1.2 6.8C1.86274 6.8 2.4 7.33726 2.4 8C2.4 8.66274 1.86274 9.2 1.2 9.2C0.537258 9.2 0 8.66274 0 8ZM14.8 6.8C14.1372 6.8 13.6 7.33726 13.6 8C13.6 8.66274 14.1372 9.2 14.8 9.2C15.4627 9.2 16 8.66274 16 8C16 7.33726 15.4627 6.8 14.8 6.8ZM11.9598 2.34316C12.4284 1.87453 13.1882 1.87453 13.6568 2.34316C14.1255 2.81179 14.1255 3.57158 13.6568 4.04021C13.1882 4.50884 12.4284 4.50884 11.9598 4.04021C11.4912 3.57158 11.4912 2.81179 11.9598 2.34316ZM4.04018 11.9598C3.57155 11.4912 2.81175 11.4912 2.34312 11.9598C1.87449 12.4284 1.87449 13.1882 2.34312 13.6569C2.81175 14.1255 3.57155 14.1255 4.04018 13.6569C4.50881 13.1882 4.50881 12.4284 4.04018 11.9598ZM2.34315 2.34316C2.81178 1.87453 3.57158 1.87453 4.04021 2.34316C4.50884 2.81179 4.50884 3.57158 4.04021 4.04021C3.57158 4.50884 2.81178 4.50884 2.34315 4.04021C1.87452 3.57158 1.87452 2.81179 2.34315 2.34316ZM13.6568 11.9598C13.1882 11.4912 12.4284 11.4912 11.9598 11.9598C11.4912 12.4284 11.4912 13.1882 11.9598 13.6569C12.4284 14.1255 13.1882 14.1255 13.6568 13.6569C14.1255 13.1882 14.1255 12.4284 13.6568 11.9598Z"
        fill="currentcolor"
      />
    </svg>
  );
};
