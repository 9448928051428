import { SVGProps } from 'react';

export const Sort = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 14 12" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1493 11.1V3.07361L11.6948 4.6191C12.0462 4.97058 12.6161 4.97057 12.9675 4.6191C13.319 4.26763 13.319 3.69778 12.9675 3.34631L9.88709 0.265862C9.53562 -0.0856097 8.96577 -0.0856095 8.6143 0.265862C8.57291 0.307249 8.5364 0.351664 8.50476 0.398394C8.40662 0.542554 8.34925 0.716701 8.34925 0.904247V11.1C8.34925 11.597 8.7522 12 9.24925 12C9.74631 12 10.1493 11.597 10.1493 11.1ZM4.74926 0.00422357C4.25221 0.00422357 3.84926 0.407167 3.84926 0.904223V8.93011L2.30519 7.38604C1.95372 7.03457 1.38387 7.03457 1.0324 7.38604C0.680931 7.73751 0.680931 8.30736 1.0324 8.65883L4.11285 11.7393C4.46432 12.0908 5.03417 12.0908 5.38564 11.7393C5.56033 11.5646 5.64819 11.336 5.64924 11.107C5.64926 11.1047 5.64926 11.1023 5.64926 11.1V0.904223C5.64926 0.407167 5.24632 0.00422357 4.74926 0.00422357Z"
        fill="currentColor"
      />
    </svg>
  );
};
