import React, { SVGProps } from 'react';

export const Search = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99533 2.80009C4.67831 2.80009 2.8 4.6784 2.8 6.99542C2.8 9.31244 4.67831 11.1907 6.99533 11.1907C8.11924 11.1907 9.13994 10.7488 9.8931 10.0292C9.91306 10.0045 9.93454 9.98057 9.95751 9.9576C9.98048 9.93463 10.0044 9.91316 10.0291 9.89319C10.7487 9.14003 11.1907 8.11934 11.1907 6.99542C11.1907 4.6784 9.31234 2.80009 6.99533 2.80009ZM11.8233 10.5506C12.557 9.55588 12.9907 8.32629 12.9907 6.99542C12.9907 3.68429 10.3065 1.00009 6.99533 1.00009C3.6842 1.00009 1 3.68429 1 6.99542C1 10.3065 3.6842 12.9907 6.99533 12.9907C8.3262 12.9907 9.55579 12.5571 10.5505 11.8234L13.4602 14.7331C13.8116 15.0845 14.3815 15.0845 14.733 14.7331C15.0844 14.3816 15.0844 13.8117 14.733 13.4603L11.8233 10.5506Z"
        fill="currentcolor"
      />
    </svg>
  );
};
