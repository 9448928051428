import React, { SVGProps } from 'react';

export const UnderVerification = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9084 2H12.3C13.7912 2 15 3.20883 15 4.7V13.3C15 14.7912 13.7912 16 12.3 16H3.7C2.20883 16 1 14.7912 1 13.3V4.7C1 3.20883 2.20883 2 3.7 2H4.09162C4.39996 0.848178 5.45093 0 6.7 0H9.3C10.5491 0 11.6 0.848178 11.9084 2ZM5.79999 2.69999C5.79999 2.20293 6.20293 1.79999 6.69999 1.79999H9.29999C9.79704 1.79999 10.2 2.20293 10.2 2.69999C10.2 3.19704 9.79704 3.59999 9.29999 3.59999H6.69999C6.20293 3.59999 5.79999 3.19704 5.79999 2.69999ZM11.8864 7.55584C12.2378 7.20436 12.2378 6.63452 11.8864 6.28304C11.5349 5.93157 10.9651 5.93157 10.6136 6.28304L6.74998 10.1466L5.38637 8.78304C5.0349 8.43157 4.46505 8.43157 4.11358 8.78304C3.76211 9.13452 3.76211 9.70436 4.11358 10.0558L6.11358 12.0558C6.28236 12.2246 6.51128 12.3194 6.74998 12.3194C6.98867 12.3194 7.21759 12.2246 7.38637 12.0558L11.8864 7.55584Z"
        fill="currentcolor"
      />
    </svg>
  );
};
