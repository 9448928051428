import { SVGProps } from 'react';

export const SocialYoutube = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0222 6.62041C20.3019 6.90209 20.5035 7.25257 20.6071 7.63704C20.9888 9.05632 21 12 21 12C21 12 21 14.9552 20.6189 16.3632C20.5154 16.7477 20.3137 17.0981 20.034 17.3798C19.7543 17.6615 19.4063 17.8646 19.0245 17.9689C17.6264 18.3529 12 18.3529 12 18.3529C12 18.3529 6.37359 18.3529 4.97578 17.9686C4.59401 17.8643 4.24598 17.6612 3.96628 17.3795C3.68657 17.0979 3.48493 16.7474 3.38137 16.3629C3 14.9436 3 12 3 12C3 12 3 9.05632 3.36984 7.64837C3.47339 7.2639 3.67504 6.91342 3.95475 6.63174C4.23445 6.35006 4.58248 6.14699 4.96425 6.04271C6.36234 5.65836 11.9887 5.64703 11.9887 5.64703C11.9887 5.64703 17.6149 5.64703 19.0127 6.03138C19.3945 6.13566 19.7425 6.33873 20.0222 6.62041ZM14.8653 12.0009L10.198 14.7234L10.1977 9.27847L14.8653 12.0009Z"
        fill="currentColor"
      />
    </svg>
  );
};
