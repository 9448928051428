import React, { SVGProps } from 'react';

export const User = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M4 4C4 1.79086 5.79086 0 8 0C10.2091 0 12 1.79086 12 4C12 6.20914 10.2091 8 8 8C5.79086 8 4 6.20914 4 4ZM8 9.79999C10.9606 9.79999 13.5455 11.4082 14.929 13.7986C12.9718 15.1851 10.581 16 7.99999 16C5.41894 16 3.02822 15.1851 1.07104 13.7986C2.45448 11.4082 5.03943 9.79999 8 9.79999Z"
        fill="currentcolor"
      />
    </svg>
  );
};
