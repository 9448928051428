import React, { SVGProps } from 'react';

export const Slash = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M10.4413 2.06692C10.901 2.25606 11.1203 2.78202 10.9311 3.24168L6.73348 13.4432C6.54434 13.9029 6.01838 14.1222 5.55872 13.9331C5.09905 13.7439 4.87975 13.218 5.06889 12.7583L9.26653 2.55675C9.45567 2.09708 9.98163 1.87778 10.4413 2.06692Z"
        fill="currentcolor"
      />
    </svg>
  );
};
